module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-plugin-mdx-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":true,"noInlineHighlight":false}},{"resolve":"gatsby-remark-emojis","options":{"active":true,"class":"emoji-icon","size":64,"styles":{"display":"inline","margin":"0","margin-top":"1px","position":"relative","top":"5px","width":"25px"}}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":830,"quality":90,"withWebp":true,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"maintainCase":false}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Four Satellites","short_name":"FourSatellites","description":"Consulting | Speaking | Education | Design","start_url":"/","background_color":"#2b2e3c","theme_color":"#3498DB","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1b7f07de83a69919a7f5cd5f4066d4dc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
